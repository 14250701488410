import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';

interface AxiosConfig extends AxiosRequestConfig {
  retryCount?: number;
  retryInterval?: number;
}

interface AxiosConfigCustom extends AxiosConfig {
  retryCount: number;
  retryInterval: number;
}

interface AxiosErrorCustom extends AxiosError {
  config: AxiosConfigCustom
}

const RETRY_INTERVAL = 1000; // 1 second

const memoConfig: AxiosConfig = {
  baseURL: `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API}/api`
};

const memo = axios.create(memoConfig);

const onSuccess = (response: AxiosResponse) => response;

const onError = (error: AxiosErrorCustom) => {
  const { config } = error;
  const { maxRetries = 0, retryInterval } = config
  if (maxRetries <= 0) return Promise.reject(error);

  const retryCount = (config.retryCount || 0) + 1;
  config.retryCount = retryCount;
  if (retryCount < maxRetries) {
    return new Promise((res) => {
      setTimeout(() => {
        res(memo.request(config))
      }, (retryInterval ?? RETRY_INTERVAL))
    });
  }
  return Promise.reject(error)
};

memo.interceptors.response.use(onSuccess, onError)

export default memo;
