import MaleDefaultLayout from './male-default-layout';
import { LayoutProps } from './layouts-interfaces';

export default function AllBlue(props: LayoutProps) {
  const { children, product } = props;
  return (
    <MaleDefaultLayout product={product} background='layout-bg-all-blue'>
      {children}
    </MaleDefaultLayout>
  );
}
