import { useEffect } from 'react';
import useRouter from 'hooks/use-router';
import { ScrollToTopProps } from './scroll-to-top-interfaces';

export default function ScrollToTop(props: ScrollToTopProps) {
  const { pathname } = useRouter();
  const { children } = props;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{children}</>;
}
