import { useEffect, useState } from 'react';
import useRouter from 'hooks/use-router';
import GenericErrorPage from 'pages/errors/generic-error-page';
import FullScreenLoading from 'components/full-screen-loading/full-screen-loading';
import { getUrlShortenerLink } from 'services/memo/memo-service';
import MainLayout from 'components/layouts/main-layout';

interface ShortenerError {
  status: number;
  message: string;
}

function getPathFromFullURL(url: string, queryParams: Record<string, string>): string {
  try {
    const link = new URL(url);
    // Update search params in url with those from query
    Object.entries(queryParams).forEach(([key, val]) => {
      link.searchParams.append(key, val)
    })
    return `${link.pathname}${link.search}`;
  } catch (err) {
    // if url is a path and not a full url, return the full string with updated search params
    const search = new URLSearchParams(`?${url.split('?')[1] ?? ''}`)
    const path = url.split('?')[0];
    Object.entries(queryParams).forEach(([key, val]) => {
      search.append(key, val)
    })
    return `${path}?${search.toString()}`;
  }
}

export default function UrlShortenerRedirect() {
  const { pathCode, history, queryParams } = useRouter();
  const [error, setError] = useState<ShortenerError>();
  useEffect(() => {
    getUrlShortenerLink(pathCode)
      .then((res) => {
      const { url } = res.data;
        if (!url) {
          return setError({
            status: 404,
            message: 'short url hash not found',
          });
        }
        if (url.includes('/membersatisfaction') || url.includes('doxy.me')) {
          return window.location.assign(url);
        }
        return history.replace(getPathFromFullURL(url, queryParams));
      })
      .catch((err) => {
        setError({
          status: err?.response?.status || 'err',
          message:
            err?.response?.data?.message || err.message || JSON.stringify(err),
        });
      });
  }, [pathCode, history, queryParams]);

  if (error) {
    return (
      <MainLayout>
        <GenericErrorPage
          message={error.message}
          code={error.status.toString()}
          description='Error: URL Shortener'
        />
      </MainLayout>
    );
  }
  return <FullScreenLoading />;
}
