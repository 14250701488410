import MaleDefaultLayout from './male-default-layout';
import { LayoutProps } from './layouts-interfaces';

export default function MainBlue(props: LayoutProps) {
  const { children, product } = props;
  return (
    <MaleDefaultLayout product={product} background='layout-bg-male-main'>
      {children}
    </MaleDefaultLayout>
  );
}
