import { LayoutProps } from 'components/layouts/layouts-interfaces';
import React from 'react';
import { Route } from 'react-router-dom';
import { getBrandInfo } from 'utils/utils';
import GenericNotFound from 'pages/errors/generic-not-found';
import MainLayout from 'components/layouts/main-layout';

const { isFemme, isMale } = getBrandInfo();

interface AppRouteProps {
  component: React.ElementType;
  layout: React.FunctionComponent<LayoutProps>;
  path?: string;

  product?: 'hrt' | 'ed';
  site?: 'male' | 'female' | 'both';
}

export default function AppRoute(props: AppRouteProps) {
  const { component, layout, path, product, site } = props;
  let display = true;
  let Component = component;
  let Layout = layout;

  if (isFemme && site === 'male') {
    display = false;
  }
  if (isMale && site === 'female') {
    display = false;
  }
  if (!display) {
    Component = GenericNotFound;
    Layout = MainLayout;
  }

  return (
    <Route
      path={path}
      render={(rProps) => (
        <Layout product={product}>
          <Component {...rProps} />
        </Layout>
      )}
    />
  );
}

AppRoute.defaultProps = {
  path: '',
  product: 'hrt',
  site: 'both',
};
