/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Link } from 'components/link';
import { Typography, TypographyProps } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import {
  MarkdownParserProps,
  OverrideChildren,
} from './markdown-parser-interfaces';

export default function MarkdownParser(props: MarkdownParserProps) {
  const {
    children: markdownChildren,
    source,
    smallParagraph,
    titlePage,
    whiteParagraph,
    overrides = {},
  } = props;

  const Root = useCallback(
    (rootProps: OverrideChildren) => {
      const { children } = rootProps;
      const RootOverrides = overrides?.root || {};
      return <span {...RootOverrides}>{children}</span>;
    },
    [overrides?.root]
  );

  const Headings = useCallback(
    (headingsProps: OverrideChildren) => {
      const { level, children } = headingsProps;
      const TypographyDefaults: TypographyProps = {
        variant: `h${level}` as Variant,
        children,
        color: level === 5 ? 'primary' : 'secondary',
        style: level === 5 ? { fontWeight: 'bold' } : {},
        gutterBottom: true,
      };

      const HeadingsOverrides = overrides?.headings || {};

      const finalProps = {
        ...TypographyDefaults,
        ...HeadingsOverrides,
      };

      return <Typography {...finalProps} />;
    },
    [overrides?.headings]
  );

  const Links = useCallback(
    (linksProps: OverrideChildren) => {
      const { href, children } = linksProps;
      const LinkProps = {
        target: '_blank',
        rel: 'noopener',
        href,
        children,
      };

      const LinkOverrides = overrides?.links || {};

      return <Link {...{ ...LinkProps, ...LinkOverrides }} />;
    },
    [overrides?.links]
  );

  const Paragraph = useCallback(
    (paragraphProps: OverrideChildren) => {
      const { children } = paragraphProps;
      const TypographyDefaults: TypographyProps = {
        children,
      };

      const ParagraphOverrides = overrides?.paragraph || {};

      if (smallParagraph) {
        TypographyDefaults.paragraph = true;
        TypographyDefaults.style = { fontSize: '18px' };
      } else if (titlePage) {
        TypographyDefaults.paragraph = true;
        TypographyDefaults.style = { fontSize: '22px' };
      } else if (whiteParagraph) {
        TypographyDefaults.variant = 'body1';
        TypographyDefaults.style = { color: 'white' };
      } else {
        TypographyDefaults.variant = 'h5';
        TypographyDefaults.paragraph = true;
      }

      return (
        <Typography {...{ ...TypographyDefaults, ...ParagraphOverrides }} />
      );
    },
    [overrides?.paragraph, smallParagraph, titlePage, whiteParagraph]
  );

  const Strong = useCallback(
    (strongProps: OverrideChildren) => {
      const { children } = strongProps;
      const StrongProps = {
        style: { fontWeight: 500 },
        children,
      };

      const StrongOverrides = overrides?.strong || {};

      return <strong {...{ ...StrongProps, ...StrongOverrides }} />;
    },
    [overrides?.strong]
  );

  const text = markdownChildren ?? source ?? '';

  const customRenderers = {
    h1: Headings,
    h2: Headings,
    h3: Headings,
    h4: Headings,
    h5: Headings,
    h6: Headings,
    h7: Headings,
    a: Links,
    p: Paragraph,
    strong: Strong,
  };

  return (
    <Root>
      <ReactMarkdown components={customRenderers} rehypePlugins={[rehypeRaw]}>
        {text}
      </ReactMarkdown>
    </Root>
  );
}
