import { ReactComponent as PhoneIcon } from 'assets/purple-phone.svg';
import { ReactComponent as FemExcelLogo } from 'assets/icons/footer-mui/femexcel/fem-excel-color.svg';
import useTailwindBreakpoints from 'hooks/use-tailwind-breakpoints';
import { Link } from 'components/link';

const phoneNumberVisual = '(833) 625-3392';
const phoneNumberFunctional = 'tel:833-625-3392';

export default function HeaderFemme() {
  const largeScreen = useTailwindBreakpoints().sizeUp('md');

  return (
    <div className='flex justify-between mx-2 md:mx-12 my-[15px] md:my-4'>
      <FemExcelLogo
        width={largeScreen ? '18rem' : '10rem'}
        height='100%'
        className='text-femexcel'
      />
      <Link href={phoneNumberFunctional} className='flex items-center'>
        <PhoneIcon
          className='mx-2 md:mx-1'
          height='100%'
          width={largeScreen ? '1.5rem' : '1.4rem'}
        />
        <div className='flex flex-col'>
          {largeScreen && (
            <div className='text-lg font-semibold'>Have questions?</div>
          )}
          <div className='text-base font-normal md:font-semibold text-femexcel-dark md:text-base mr-1 md:mr-0'>
            {largeScreen ? phoneNumberVisual : 'Call now'}
          </div>
        </div>
      </Link>
    </div>
  );
}
