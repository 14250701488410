import { ReactComponent as MaleExcelLogoNew } from 'assets/male-excel-color.svg';
import useTailwindBreakpoints from 'hooks/use-tailwind-breakpoints';

export default function TailwindHeader() {
  const largeScreen = useTailwindBreakpoints().sizeUp('md');

  return (
    <div className="flex flex-row justify-center py-6 border-b">
      <MaleExcelLogoNew
        width={largeScreen ? '10rem' : '9rem'}
        height='100%'
      />
    </div>
  )
}
