import useProducts from 'hooks/use-product';
import { PropsWithChildren, createContext } from 'react';
import { ProductResponse } from 'services/memo/memo-service';
import { getBrandInfo } from 'utils/utils';

const { isFemme } = getBrandInfo();

interface ProductContextDefaults {
  product: ProductResponse;
  loading: boolean;
}

export const ProductContext = createContext<ProductContextDefaults>({
  product: {
    externalId: 'undefined',
    category: isFemme ? 'fhrt' : 'hrt',
    type: 'product',
    brand: isFemme ? 'Femexcel' : 'Maleexcel',
    description: '',
    externalCampaignId: 'undefined',
    message: '',
    name: '',
    packageIncludes: [],
    includesDetail: [],
    price: 0,
    quantity: 1,
    salePrice: '',
    shipping: '',
    total: '',
    mpId: undefined,
  },
  loading: true,
});

export default function ProductContextProvider({
  children,
}: PropsWithChildren) {
  const productInfo = useProducts();
  return (
    <ProductContext.Provider value={productInfo}>
      {children}
    </ProductContext.Provider>
  );
}
