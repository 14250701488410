import { useContext, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import { ErrorSnackbarContext } from './error-snackbar-provider';

const TEN_SECONDS = 1000 * 10;

export default function ErrorSnackbar() {
  const { snackbarState, setSnackbarControl } =
    useContext(ErrorSnackbarContext);
  const { open, message } = snackbarState;

  const timer = useRef(setTimeout(() => true, 1))

  useEffect(() => {
    if (!open) return () => true;
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setSnackbarControl({ open: false, message: '' });
    }, TEN_SECONDS);
    return () => clearTimeout(timer.current);
  }, [open, message, setSnackbarControl]);

  return (
    <div className='fixed top-0 left-0 right-0 z-50'>
      <Transition
        show={open}
        enter='transition duration-150'
        enterFrom='scale-95 opacity-0'
        enterTo='scale-100 opacity-100'
        leave='transition duration-75'
        leaveFrom='scale-100 opacity-100'
        leaveTo='scale-95 opacity-0'
      >
        <div className='text-md absolute top-0 left-0 right-0 mx-4 mt-4 flex max-w-lg items-center gap-4 rounded bg-red-500 p-4 text-left font-sans text-white shadow-md md:mx-auto'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth='1.5'
            stroke='currentColor'
            className='h-12 w-12 md:h-8 md:w-8'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z'
            />
          </svg>

          {message}
        </div>
      </Transition>
    </div>
  );
}
