import Header from 'components/layouts/header';
import { LayoutProps } from './layouts-interfaces';
import FooterMUI from './footer/footer-mui';

export default function MaleDefaultLayout(props: LayoutProps) {
  const { children, product } = props;

  return (
    <div
      className='flex min-h-screen flex-col content-around items-center bg-body-blue-grey'
    >
      <div className='w-full px-2 bg-header-blue-grey border-b-[1px] header-bottom-border'>
        <Header product={product} />
      </div>
      <div className='min-h w-fix my-4 min-h-[calc(100vh-26rem)] max-w-[98vw] content-center p-0 md:my-8'>
        {children}
      </div>
      <FooterMUI />
    </div>
  );
}

MaleDefaultLayout.defaultProps = {
  background: 'layout-bg-male-main',
};
