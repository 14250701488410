import FemmeMainLayout from './femme-main';
import { LayoutProps } from './layouts-interfaces';

export default function FemmeFullGrey(props: LayoutProps) {
  const { children, product } = props;
  return (
    <FemmeMainLayout background='bg-full-grey' product={product}>
      {children}
    </FemmeMainLayout>
  );
}
