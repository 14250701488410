import { getBrandInfo } from 'utils/utils';
import FemmeMainLayout from './femme-main';
import MainBlue from './main-blue';
import { LayoutProps } from './layouts-interfaces';

const { isFemme } = getBrandInfo();

export default function MainLayout({
  children,
  product,
  background,
}: LayoutProps) {
  if (isFemme) {
    return (
      <FemmeMainLayout product={product} background={background}>
        {children}
      </FemmeMainLayout>
    );
  }

  return <MainBlue product={product}>{children}</MainBlue>;
}
