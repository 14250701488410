import { withRouter } from 'react-router-dom';
import GenericErrorPage from './generic-error-page';

const GenericNotFound = () => (
  <GenericErrorPage
    code='404'
    description='Oops... page not found'
    message=''
  />
);

export default withRouter(GenericNotFound);
