import { useMemo } from 'react';
import { decodeQueryString, getBrandInfo } from 'utils/utils';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { FormId, PathCode } from 'interfaces/common-interfaces';

const { portalLink } = getBrandInfo()

export type State = Record<string, unknown>;

const pathCodeCoalesce: Record<string, PathCode> = {
  '/quiz': 'quiz'
}

const useRouter = <
  LocationType = Record<string, never>,
  HistoryType = Record<string, unknown>
>() => {
  const params = useParams<Record<string, string>>();
  const location = useLocation<LocationType>();
  const history = useHistory<HistoryType>();
  const match = useRouteMatch<Record<string, string | undefined>>();
  const { pathCode: rawPathCode } = params;
  const state = location?.state as Record<string, unknown>;
  const userData = state?.userData as Record<string, unknown>;
  const queryParams = useMemo(
    () => decodeQueryString(location.search),
    [location.search]
  );
  const landingId =
    queryParams?.landing_id ??
    userData?.landing_id ??
    userData?.landingId ??
    (state?.landingId as Record<string, unknown>)?.landing_id ??
    state?.landingId ??
    '';
  const pathCode = rawPathCode?.toLowerCase() as PathCode ?? pathCodeCoalesce[location.pathname];
  const formId = (queryParams?.formId as FormId) ?? '';

  const prefill: Record<string, unknown> = {};

  if (queryParams.prefill) {
    try {
      const fillParams = queryParams.prefill
        ? JSON.parse(atob(queryParams.prefill))
        : {};
      // individually declaring values in case there is an error in the prefill string.
      prefill.firstName = fillParams?.firstName;
      prefill.lastName = fillParams?.lastName;
      prefill.phone = fillParams?.phone;
      prefill.email = fillParams?.email;
    } catch (err) {
      console.error(err as Error);
    }
  }

  let portalBacklink: string | undefined;
  if (queryParams.portal && process.env.REACT_APP_PATIENT_PORTAL_URL) {
    const patientPortalPath = decodeURIComponent(queryParams.portal);
    portalBacklink = `${portalLink}${patientPortalPath}`;
  }

  return {
    push: history.push,
    replace: history.replace,
    pathname: location.pathname,
    queryParams,
    params,
    allParams: { ...queryParams, ...params },
    landingId,
    rawPathCode: pathCode,
    pathCode,
    formId,
    match,
    location,
    history,
    prefill,
    portalBacklink,
  };
};

export default useRouter;
