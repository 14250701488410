import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const breakpoints = createBreakpoints({});
const theme = createTheme({
  palette: {
    primary: {
      main: '#ae1a23',
      light: '#a02e2a',
    },
    secondary: {
      light: '#8a8a8a',
      main: '#141414',
      dark: '#000000',
    },
    info: {
      light: '#404040',
      dark: '#000',
      main: '#ae1a23',
      contrastText: 'rgba(23, 22, 22, 0.6)',
    },
  },

  breakpoints,
  props: {
    MuiOutlinedInput: {
      notched: false,
      color: 'secondary'
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        color: '#a3a3a3', // tw-neutral-400
        width: '40px',
        height: '40px',
        padding: '3px',
        zIndex: 1,
      },
      active: {
        color: '#66bb6a !important'
      },
      completed: {
        color: '#66bb6a !important',
        // Fills in the checkmark with white
        backgroundImage: 'radial-gradient(white 50%, transparent 50%)'
      }
    },
    MuiStepLabel: {
      labelContainer: {
        marginTop: '-8px',
      },
      active: {
        '&$alternativeLabel': {
          marginTop: '13px',
        },
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        left: 'calc(-50% + 15px)',
        right: 'calc(50% + 15px)',
      },
      active: {
        '& $line': {
          borderColor: '#66bb6a',
        },
      },
      completed: {
        '& $line': {
          borderColor: '#66bb6a',
        },
      },
      lineHorizontal: {
        borderTopWidth: '4px',
        borderColor: '#525252', // tw-neutral-600
        marginTop: '9.5px',
        zIndex: 0
      },
    },
    MuiTypography: {
      h1: {
        fontWeight: 900,
      },
      h2: {
        fontWeight: 900,
      },
      h3: {
        fontWeight: 900,
      },
      h4: {
        fontWeight: 900,
      },
      h5: {
        fontWeight: 900,
      },
      h6: {
        fontWeight: 700,
      },
      body1: {
        fontWeight: 400,
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0.25rem',
        backgroundColor: 'white',
        '&$focused $notchedOutline': {
          borderWidth: '2px',
        },
        '&:hover $notchedOutline': {
          borderColor: '#6A6A6A',
        },
      },
      notchedOutline: {
        borderColor: '#bbb',
        borderWidth: '2px',
      },
    },
    MuiSelect: {
      outlined: {
        backgroundColor: 'white',
        '&:focus': {
          backgroundColor: 'white',
        },
      },
    },
  },
});

const responsiveTheme = responsiveFontSizes(theme);
export default responsiveTheme;
