import { Suspense, ElementType } from 'react';
import FullScreenLoading from 'components/full-screen-loading/full-screen-loading';

const suspenseWrapper = (Component: ElementType) => (props) =>
  (
    <Suspense fallback={<FullScreenLoading />}>
      <Component {...props} />
    </Suspense>
  );

export default suspenseWrapper;
