import React from 'react';
import clsx from 'clsx';
import { getBrandInfo } from 'utils/utils';

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {}

const { isFemme, isMale } = getBrandInfo();

export default function Link(props: LinkProps) {
  const { children, ...rest } = props;

  return (
    <a
      className={clsx(
        isFemme && 'text-femexcel-dark',
        isMale && 'text-scarlet'
      )}
      {...rest}
    >
      {children}
    </a>
  );
}
