import { useCallback, useEffect, useState } from 'react';

type TailwindBreakpoint<T extends string = 'xs'> =
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | '2xl'
  | T;

/**
 * default sizes:

 * sm: 640

 * md: 768

 * lg: 1024

 * xl: 1280

 * 2xl: 1535

 */
const twDefaultBreakpoints: Record<TailwindBreakpoint, number> = {
  xs: 0,
  sm: 639,
  md: 767,
  lg: 1023,
  xl: 1279,
  '2xl': 1535,
};

function getWindowWidth() {
  const { innerWidth: width } = window;
  return { width };
}

function getBreakpoint(): TailwindBreakpoint {
  const { width } = getWindowWidth();
  const { sm, md, lg, xl } = twDefaultBreakpoints;
  const xl2 = twDefaultBreakpoints['2xl'];
  if (width > xl2) {
    return '2xl';
  }
  if (width > xl) {
    return 'xl';
  }
  if (width > lg) {
    return 'lg';
  }
  if (width > md) {
    return 'md';
  }
  if (width > sm) {
    return 'sm';
  }
  return 'xs';
}

/**
 * Gets the current screen width based on defaults from Tailwind.
 * Note that 'xs' isn't actually a Tailwind utility class, but indicates that
 * the viewport is actually smaller than that breakpoint.

 * There is about a 1px difference between when this updates and when tailwind updates.
 * This should still cover most cases.
 * @returns 'xs', 'sm', 'md', 'lg', 'xl', '2xl' based on screen width
 */
export default function useTailwindBreakpoints() {
  const [currentBreakpoint, setCurrentBreakpoint] =
    useState<TailwindBreakpoint>(getBreakpoint());

  useEffect(() => {
    function handleResize() {
      setCurrentBreakpoint(getBreakpoint());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sizeUp = useCallback(
    (size: TailwindBreakpoint) =>
      twDefaultBreakpoints[currentBreakpoint] >= twDefaultBreakpoints[size],
    [currentBreakpoint]
  );

  const sizeDown = useCallback(
    (size: TailwindBreakpoint) =>
      twDefaultBreakpoints[currentBreakpoint] <= twDefaultBreakpoints[size],
    [currentBreakpoint]
  );

  return { size: currentBreakpoint, sizeUp, sizeDown };
}
