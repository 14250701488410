import useRouter from 'hooks/use-router';
import { useState, useEffect } from 'react';
import { getBrandInfo } from 'utils/utils';

type Mapping = { [pathName: string]: string[] };

/**
 * This describes the different routes for each pathCode.
 * Currently for ED, the checkout redirects to other pages somewhat dynamically.
 * Because of this we can't define them here, yet.
 */
const ROUTES: Mapping = {
  // ED paths
  dailytada: ['/mip/form', '/mip/checkout'],
  dailytada2: ['/mip/form', '/mip/checkout'],
  groupdc: ['/mip/form', '/mip/checkout'],
  groupc: ['/mip/form', '/mip/checkout'],
  groupv: ['/mip/form', '/mip/checkout'],
  callcenter: ['/mip/form', '/mip/checkout'],
  'a2-renewal': ['/mip/form', '/mip/cont/ced'],
  '6via': ['/mip/form', '/mip/checkout'],
  '6cial': ['/mip/form', '/mip/checkout'],

  // HRT paths
  shrt: [
    '/hormone-assessment',
    '/results',
    '/payment',
    '/mip/form',
    '/mip/calendly',
  ],
  ghrt: [
    '/hormone-assessment',
    '/results',
    '/payment',
    '/mip/form',
    '/mip/calendly',
  ],
  quiz: [
    '/results',
    '/payment',
    '/mip/form',
    '/mip/calendly',
  ],
  cchrt: ['/hormone-assessment', '/mip/form', '/mip/calendly'],
  bhrt: ['/payment', '/hormone-assessment', '/mip/form', '/mip/calendly'],
  fha1: [
    '/f/hormone-assessment',
    '/f/results',
    '/f/payment',
    '/f/intake',
    '/f/appointment/consult',
  ],
  // the following are deprecated, but kept for legacy support.
  microclinic: ['/payment', '/mip/hrt/finish', '/mip/form', '/mip/calendly'],
  microbh: [
    '/payment',
    '/mip/calendly',
    '/hormone-assessment',
    '/mip/form',
    '/mip/calendly',
  ],
  microcc: ['/hormone-assessment', '/mip/form', '/mip/calendly'],
};

const { isFemme } = getBrandInfo();

const maleOverrides = {
  cal: '/mip/calendly',
  intake: '/mip/form',
  assess: '/hormone-assessment',
  end: '/finish',
  res: '/results',
  pay: '/payment'
}

const femaleOverrides = {
  cal: '/f/appointment/consult',
  intake: '/f/intake',
  assess: '/f/hormone-assessment',
  end: '/finish',
  res: '/f/results',
  pay: '/f/payment'
}

// Sometimes we want callcenters to go a different route
const CALLCENTER_ROUTES: Mapping = {
  shrt: ['/payment', '/finish'],
};

/**
 * This describes which stack (HRT or ED) each route belongs to.
 * Useful for when you want to do some functionality specific to one stack.
 */
export const ROUTE_STACK: Mapping = {
  HRT: [
    'shrt1',
    'shrt2',
    'cchrt',
    'bhrt',
    'microclinic', // deprecated, legacy only
    'microbh', // deprecated, legacy only
    'microcc', // deprecated, legacy only
    'ghrt',
    'fha1',
    'shrt',
  ],
  ED: [
    'dailytada',
    'dailytada2',
    'groupc',
    'groupdc',
    'groupv',
    'callcenter',
    '6via',
    '6cial',
    'a2-renewal',
  ],
  ASYNC_ASSESSMENT: ['microbh', 'microcc', 'cchrt'],
  // deprecated, legacy only
  MICROCLINIC: ['microclinic', 'microbh', 'microcc'],
};

export default function usePathCode(newPathCode?: string) {
  // prettier-ignore
  const { location, queryParams, rawPathCode } = useRouter();
  const { pathOverride } = queryParams;
  const [nextPath, setNextPath] = useState('');
  const [prevPath, setPrevPath] = useState('');

  useEffect(() => {
    if (pathOverride) {
      const overrides = isFemme ? femaleOverrides : maleOverrides;
      const steps = pathOverride.split(',');
      const currentStepIndex = steps.findIndex(
        (step) => location.pathname.startsWith(overrides[step])
      );
      const nextStep = steps[currentStepIndex + 1];
      setNextPath(`${overrides[nextStep] || overrides.end}/${newPathCode || rawPathCode}`)
      return;
    }
    const config =
      queryParams?.callcenter === 'true' ? CALLCENTER_ROUTES : ROUTES;
    const pathnameMinusPathCode = location.pathname
      .replace(rawPathCode, '')
      .slice(0, -1);

    const routeSteps = config[newPathCode || rawPathCode.toLowerCase()];
    if (!routeSteps) {
      window.location.href = `/error${location.search}`;
    }
    const currentStepIndex = routeSteps.indexOf(pathnameMinusPathCode);
    const nextStep = routeSteps[currentStepIndex + 1];
    const prevStep = routeSteps[currentStepIndex - 1];

    const nextPathTemp =
      nextStep && nextStep.includes('http')
        ? `${nextStep}${location.search}`
        : `${nextStep}/${newPathCode || rawPathCode}`;
    const prevPathTemp = `${prevStep}/${rawPathCode}`;

    setNextPath(nextPathTemp);
    setPrevPath(prevPathTemp);
  }, [location, newPathCode, pathOverride, queryParams, rawPathCode]);

  return [nextPath, prevPath];
}
