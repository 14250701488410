import React, { useState, useMemo } from 'react';

import { Plan } from 'components/order-card';
import {
  ShoppingCartInterface,
  ShoppingCartProps,
} from './shopping-cart-interface';

export const ShoppingCartContext = React.createContext<ShoppingCartInterface>(
  {} as ShoppingCartInterface
);

export const ShoppingCartProvider = (props: ShoppingCartProps) => {
  const { children } = props;
  const [shoppingCart, setShoppingCart] = useState({} as Plan);

  const context = useMemo(
    () => ({
      item: shoppingCart,
      update: setShoppingCart,
    }),
    [shoppingCart, setShoppingCart]
  );

  return (
    <ShoppingCartContext.Provider value={context}>
      {children}
    </ShoppingCartContext.Provider>
  );
};
