import { useState, useEffect, ReactNode } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Container,
  makeStyles,
} from '@material-ui/core';
import { getMarkdown } from 'services/memo/memo-service';
import MarkdownParser from 'components/markdown-parser/markdown-parser';
import { AdditionalContact } from 'components/additional-contact';
import { ROUTE_STACK } from 'hooks/use-path-code';
import useRouter from 'hooks/use-router';
import useErrorSnackbar from 'hooks/use-error-snackbar';
import { ErrorProps } from './errors-interface';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paperMain: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10, 5, 5, 5),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(10, 5, 5, 5),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10, 10, 10, 10),
      backgroundSize: 'auto',
    },
    width: '800px',
    maxWidth: '92vw',
    backgroundColor: '#F3F3F3',
    borderRadius: 16,
    marginBottom: theme.spacing(4),
    boxSizing: 'border-box',
  },

  paperEnd: {
    width: '800px',
    maxWidth: '92vw',
    backgroundColor: '#F3F3F3',
    borderRadius: 16,
  },
  code: {
    fontWeight: 300,
    fontSize: theme.spacing(15),
  },
  description: {
    fontWeight: 400,
  },
  message: {
    fontWeight: 500,
  },
}));

export default function GenericErrorPage(props: ErrorProps) {
  const { location, match, pathCode } = useRouter();
  const { snackbarCatch } = useErrorSnackbar();
  const [pageText, setPageText] = useState({});
  const { code, description, message } = props;
  const state = location?.state;
  const codeView = (match?.params?.code as string) || code;
  const descriptionView = (state?.error as string) || description;
  const version = ROUTE_STACK.HRT.includes(pathCode) ? 'HRT' : 'ED';
  const classes = useStyles();

  useEffect(() => {
    getMarkdown(['error404-ED', 'error404-HRT'])
      .then((resp) => setPageText(resp.data.results))
      .catch(snackbarCatch);
  }, [snackbarCatch]);

  return (
    <Container disableGutters maxWidth='lg'>
      <Paper className={classes.paperMain}>
        <Grid
          container
          direction='column'
          justifyContent='flex-end'
          alignItems='flex-start'
          spacing={3}
        >
          <Grid item>
            <Typography variant='h1' className={classes.code}>
              {codeView as ReactNode}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='h4' className={classes.description}>
              {descriptionView}
            </Typography>
            <Typography variant='h6' className={classes.message}>
              {!!message && message}
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              maxWidth: '600px',
            }}
          >
            <MarkdownParser
              smallParagraph
              source={pageText[`error404-${version}`]}
              overrides={{
                paragraph: { style: { fontWeight: 500, fontSize: '1.25rem' } },
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.paperEnd}>
        <AdditionalContact>
          If you have any questions about the test kit or our medical
          requirements give us a call at 833-411-0966 to speak to one of our
          hormone experts
        </AdditionalContact>
      </Paper>
    </Container>
  );
}
