import { useContext } from 'react';
import { ErrorSnackbarContext } from 'components/error-snackbar/error-snackbar-provider';

export const useErrorSnackbar = () => {
  const { snackbarCatch, snackbarState, setSnackbarControl } =
    useContext(ErrorSnackbarContext);
  return { snackbarCatch, snackbarState, setSnackbarControl };
};

export default useErrorSnackbar;
