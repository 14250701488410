import { useCallback, useState, useMemo, createContext } from 'react';

import { endpointFromURL } from 'utils/utils';
import {
  SnackbarContextInterface,
  SnackbarProviderProps,
} from './error-snackbar-interfaces';

const contextDefaults: SnackbarContextInterface = {
  setSnackbarControl: () => null,
  snackbarCatch: () => null,
  snackbarState: {
    open: false,
    message: '',
  },
};

export const ErrorSnackbarContext = createContext(contextDefaults);

export const ErrorProvider = (props: SnackbarProviderProps) => {
  const { children } = props;
  const [snackbarState, setSnackbarControl] = useState({
    open: false,
    message: '',
  });

  const snackbarCatch = useCallback((error) => {
    if (error.response) {
      const { status, data, request } = error.response;
      const endpoint = endpointFromURL(request.responseURL);
      setSnackbarControl({
        open: true,
        message: `/${endpoint}: ${status}: ${
          data?.details || data?.message || ''
        }`,
      });
    } else {
      setSnackbarControl({
        open: true,
        message: `${error || 'Request Timeout'}`,
      });
    }
  }, []);

  const context: SnackbarContextInterface = useMemo(
    () => ({
      snackbarState,
      setSnackbarControl,
      snackbarCatch,
    }),
    [snackbarCatch, snackbarState]
  );

  return (
    <ErrorSnackbarContext.Provider value={context}>
      {children}
    </ErrorSnackbarContext.Provider>
  );
};
