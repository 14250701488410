import { useEffect } from 'react';
import { updateHRTPatient } from 'services/memo/memo-service';
import useErrorSnackbar from './use-error-snackbar';
import useRouter from './use-router';

export default function useMPID() {
  const { snackbarCatch } = useErrorSnackbar();
  const { queryParams, landingId, location } = useRouter();
  const { mpid } = queryParams;

  useEffect(() => {
    if (!mpid) return;
    updateHRTPatient({ mpid, landingId, location }).catch(snackbarCatch);
  }, [location, landingId, mpid, snackbarCatch]);
}
