import React from 'react';

export interface AdditionalContactProps {
  children: React.ReactNode;
}

export default function AdditionalContact(props: AdditionalContactProps) {
  const { children } = props;

  return (
    <div className='flex flex-col gap-4 p-4 font-sans text-base font-semibold md:items-center md:text-center md:text-lg'>
      <div className='max-w-2xl'>{children}</div>
      <div className='flex flex-col gap-4 md:flex-row md:justify-center'>
        <a
          role='button'
          href='tel:8334110966'
          className='rounded-md border-2 border-stone-900 bg-white p-4 text-center text-lg font-semibold'
        >
          Call now
        </a>
        <a
          role='button'
          target='_blank'
          rel='noopener noreferrer'
          href='https://calendly.com/male-excel-hrt/male-excel-hrt-consult-qualification?month=2021-02'
          className='rounded-md border-2 border-stone-900 bg-white p-4 text-center text-lg font-semibold'
        >
          Schedule a call
        </a>
      </div>
    </div>
  );
}
