import { ReactComponent as PhoneIcon } from 'assets/red-phone.svg';
import { ReactComponent as MaleExcelLogoNew } from 'assets/icons/footer-mui/maleexcel/male-excel-color.svg';
import useTailwindBreakpoints from 'hooks/use-tailwind-breakpoints';
import { Link } from 'components/link';

const phoneNumberVisual = '(833) 625-3392';
const phoneNumberFunctional = 'tel:833-625-3392';

export default function Header() {
  const largeScreen = useTailwindBreakpoints().sizeUp('md');

  return (
    <div className='flex justify-between mx-2 md:mx-12 my-3 md:my-3'>
      <MaleExcelLogoNew
        width={largeScreen ? '20rem' : '12rem'}
        height='100%'
      />
      <Link href={phoneNumberFunctional} className='flex items-center'>
        <PhoneIcon
          className='mx-2 md:mx-1'
          height='100%'
          width={largeScreen ? '1.5rem' : '1.4rem'}
        />
        <div className='flex flex-col'>
          {largeScreen && (
            <div className='text-lg font-semibold'>Have questions?</div>
          )}
          <div className='text-base font-normal md:font-semibold text-scarlet md:text-base mr-1 md:mr-0'>
            {largeScreen ? phoneNumberVisual : 'Call now'}
          </div>
        </div>
      </Link>
    </div>
  );
}

Header.defaultProps = {
  product: 'hrt',
};
