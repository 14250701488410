import TailwindHeader from './tailwind-header';
import { LayoutProps } from './layouts-interfaces';

export default function TailwindLayout(props: LayoutProps) {
  const { children } = props;
  return (
    <div className="mx-auto min-h-screen" style={{ background: '#FAFAFA' }}>
      <TailwindHeader />
      <div className="grid grid-cols-1">
        { children }
      </div>
    </div>
  )
}
