import { useState, useEffect } from 'react';
import { getBrandInfo } from 'utils/utils';
import {
  ProductResponse,
  getProduct,
} from 'services/memo/memo-service';
import useRouter from './use-router';
import useErrorSnackbar from './use-error-snackbar';

const { isFemme } = getBrandInfo();
const category = isFemme ? 'fhrt' : 'hrt';

export default function useProducts() {
  const { queryParams }: { queryParams: { productId?: ProductResponse['externalId'], mpid?:ProductResponse['mpId']} } =
    useRouter();
  const { snackbarCatch } = useErrorSnackbar();
  const { productId, mpid } = queryParams;
  const type = 'product';

  const [product, setProduct] = useState<ProductResponse>(
    {} as ProductResponse
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getProduct({ id: productId ?? 'undefined', category, type })
      .then((resp) => {
        const { data } = resp;
        setLoading(false);
        if (!productId || productId === 'undefined') {
          data.total = '$99';
          data.salePrice = '';
        }
        if (!mpid) {
        setProduct(data);
        } else {
          data.mpId = mpid;
          setProduct(data);
        }
      })
      .catch(snackbarCatch);
  }, [productId, mpid, snackbarCatch]);

  return { product, loading };
}
